import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import {get} from 'lodash'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.prototype.$get = get
Vue.prototype.$imgPlaceholder = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

Vue.config.productionTip = false

const lazySizes = () => {
  import(/* webpackChunkName: "lazysizes" */ 'lazysizes/plugins/attrchange/ls.attrchange')
  return import(/* webpackChunkName: "lazysizes" */ 'lazysizes')
}
Vue.use(lazySizes)

// FILTERS
import './filters/formatDate'
import './filters/formatPrice'

// V-CLICK-OUT
import './directives/v-click-out'

// BASE COMPONENTS
import './baseComponents'
import './assets/less/style.less'

import VueToastr from 'vue-toastr'
// use plugin
Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultProgressBar: false,
  defaultTimeout: 8000,
})

// Scroll
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo)

// Modal
import vModal from './plugins/v-modal'
import TheModals from './components/TheModals'

Vue.use(vModal, {
  store,
  router,
  i18n,
  ModalsList: TheModals
})

export const mainApp = new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
