export default function (date) {
  if(!date) return

  if(typeof date === 'number') {
    date = date * 1000
  }

  date = new Date(date)
  let day = date.getDate()
  if(isNaN(day)) return

  day = day.toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString().padStart(2, '0')

  return `${day}.${month}.${year}`
}

export function formatDateForTimeTag(date) {
  if(!date) return

  if(typeof date === 'number') {
    date = date * 1000
  }

  date = new Date(date)
  let day = date.getDate()
  if(isNaN(day)) return

  day = day.toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}
