import i18n, {loadLanguageAsync} from "@/i18n";
import getLangObjByShowCode from "@/i18n/common/getLangObjByShowCode";
import getLangObjByCode from '@/i18n/common/getLangObjByCode'

export default async function (to, from, next) {
  const languageShowCode = to.params.locale
  let langObj = getLangObjByShowCode(languageShowCode)

  if (!langObj) {
    langObj = getLangObjByCode(i18n.locale)
    let path = to.fullPath

    if (!path.startsWith('/')) {
      path = '/' + path
    }
    if (!path.endsWith('/')) {
      path = path + '/'
    }

    if (path.startsWith(`/${langObj.show_code}/`)) {
      return next()
    }

    path = '/' + langObj.show_code + path

    path = path.slice(0, path.length - 1)
    return next(path)
  }

  if (i18n.locale !== langObj.code) {
    await loadLanguageAsync(langObj.code)
  }

  next()
}
