<template>
  <footer class="page-footer container">
    <div class="page-footer__inner container-inner">
      <div class="page-footer__main">
        <div class="page-footer__top">
          <div class="page-footer__main-col">
            <div class="page-footer__main-logo">
              <div v-if="$route.name === 'home'"
                   class="page-footer__logo">
                <img src="../assets/img/svg/logo-text-white.svg" alt="Solo">
                <span v-if="isNewYearMod"
                      class="page-footer__logo-hat"
                      :style="`background-image: url(${require('@/assets/img/svg/santa_hat.svg')})`"></span>
              </div>
              <router-link v-else
                           :to="{name: 'home'}"
                           @click.native="$scrollTo({x: 0, y: 0})"
                           class="page-footer__logo">
                <img src="../assets/img/svg/logo-text-white.svg" alt="Solo">
                <span v-if="isNewYearMod"
                      class="page-footer__logo-hat"
                      :style="`background-image: url(${require('@/assets/img/svg/santa_hat.svg')})`"></span>
              </router-link>
            </div>
            <div class="page-footer__main-social" v-if="socials && socials.length">
              <div class="page-footer__socials">
                <a v-for="social in socials"
                   :key="social.key"
                   :href="social.link"
                   target="_blank"
                   :title="social.key"
                   class="page-footer__socials-link">
                  <BaseSvg :name="social.iconName"/>
                </a>
              </div>
            </div>
            <div class="page-footer__main-payment">
              <div class="page-footer__payment-item">
                <BaseSvg name="ico-mastercard" width="4rem" height="4rem"/>
              </div>
              <div class="page-footer__payment-item">
                <BaseSvg name="ico-visa" width="4rem" height="4rem"/>
              </div>
            </div>

          </div>
          <div class="page-footer__main-col">
            <PageMobAccordion :title="$t('Меню')"
                              class="page-footer__accordion"
            >
              <div class="page-footer__main-list">
                <ul class="page-footer__menu">
                  <template v-for="(item, index) in menuLinks">
                    <li :key="index"
                        v-if="!item.pageId || $get(settings, 'pages', []).some(page => page === item.pageId)"
                        class="page-footer__menu-item">
                      <router-link :to="{ name: item.nameUrl, params: item.params }"
                                   class="page-footer__menu-link">{{ $t(item.name) }}
                      </router-link>
                    </li>
                  </template>
                </ul>
              </div>
            </PageMobAccordion>

          </div>
          <div class="page-footer__main-col">
            <PageMobAccordion :title="$t('Адреси')"
                              class="page-footer__accordion"
            >
              <div class="page-footer__main-list">
                <OurLocation :items="restaurants"/>
              </div>
              <MobApps class="d-md-none"/>
            </PageMobAccordion>
          </div>
          <div class="page-footer__main-col">
            <PageMobAccordion :title="$t('Контакти')"
                              class="page-footer__accordion"
            >
              <div class="page-footer__main-list">
                <OurContact class="page-footer__our-contact"/>
              </div>
            </PageMobAccordion>
            <button
                @click.prevent="CallBack"
                class="page-footer__call-back">
              {{ $t('Передзвонити?') }}
            </button>
          </div>
        </div>
        <MobApps class="d-none d-md-block"/>


      </div>
      <div class="page-footer__bottom">
        <div class="page-footer__bottom-col">
          <div class="page-footer__copyright">Solo Pizza © {{ date }}</div>
        </div>
        <div class="page-footer__bottom-col">
          <div class="page-footer__develop">{{ $t('Створено в') }}<a href="https://domino-it.agency/"
                                                                                  target="_blank">
            <svg viewBox="0 0 166 28" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                    d="M42.165 28c3.98 0 7.338-1.343 10.077-4.027 2.764-2.71 4.146-6.035 4.146-9.973 0-3.938-1.382-7.25-4.146-9.934C49.502 1.356 46.144 0 42.165 0c-3.978 0-7.35 1.355-10.115 4.066-2.738 2.685-4.108 5.996-4.108 9.934 0 3.938 1.37 7.262 4.108 9.973C34.815 26.657 38.187 28 42.165 28Zm0-6.712c-2.093 0-3.836-.678-5.232-2.033-1.395-1.381-2.092-3.133-2.092-5.255s.697-3.861 2.092-5.216c1.396-1.381 3.14-2.072 5.232-2.072 2.093 0 3.837.69 5.232 2.072 1.395 1.355 2.093 3.094 2.093 5.216 0 2.122-.698 3.874-2.093 5.255-1.395 1.355-3.139 2.033-5.232 2.033ZM20.424 4.449C17.918 1.867 14.79.575 11.045.575H0v26.85h11.045c3.746 0 6.873-1.279 9.379-3.836 2.532-2.582 3.798-5.779 3.798-9.589 0-3.81-1.266-6.993-3.798-9.55Zm-4.767 14.5c-1.189 1.226-2.726 1.84-4.612 1.84H7.131V7.211h3.914c1.886 0 3.423.627 4.612 1.88 1.188 1.227 1.782 2.864 1.782 4.91 0 2.045-.594 3.694-1.782 4.947ZM88.825.574v26.85h-6.898v-14.46l-6.588 10.778h-.698l-6.55-10.74v14.422h-6.937V.575h6.937l6.899 11.469L81.927.575h6.898Zm21.882 3.303L104.552.712 92.041 24.536l6.156 3.166 12.51-23.824Zm15.15-3.303h6.898v26.85h-5.348l-9.572-13.502v13.502h-6.938V.575h5.387l9.573 13.502V.575ZM151.777 28c3.978 0 7.337-1.343 10.076-4.027C164.618 21.263 166 17.938 166 14c0-3.938-1.382-7.25-4.147-9.934C159.114 1.356 155.755 0 151.777 0c-3.979 0-7.351 1.355-10.115 4.066-2.739 2.685-4.108 5.996-4.108 9.934 0 3.938 1.369 7.262 4.108 9.973 2.764 2.684 6.136 4.027 10.115 4.027Zm0-6.712c-2.093 0-3.837-.678-5.232-2.033-1.395-1.381-2.093-3.133-2.093-5.255s.698-3.861 2.093-5.216c1.395-1.381 3.139-2.072 5.232-2.072 2.092 0 3.836.69 5.231 2.072 1.396 1.355 2.093 3.094 2.093 5.216 0 2.122-.697 3.874-2.093 5.255-1.395 1.355-3.139 2.033-5.231 2.033Z"></path>
            </svg>
          </a></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

  import {mapGetters, mapState} from 'vuex'
  import PageMobAccordion from './PageMobAccordion'
  import getSocials from '../helpers/getSocials'
  import MobApps from './MobApps'
  import OurContact from './OurContact'
  import OurLocation from './OurLocation'

  export default {
    name: 'TheFooter',
    components: {
      MobApps,
      OurContact,
      PageMobAccordion,
      OurLocation
    },
    data() {
      return {
        socials: [],
        restaurants: []
      }
    },
    computed: {
      ...mapState('navigation', ['menuLinks']),
      ...mapState('settings', ['settings']),
      ...mapGetters(['isNewYearMod']),
      date() {
        const date = new Date()
        return date.getFullYear()
      }
    },
    methods: {
      CallBack() {
        this.$vModal.open('call-back')
      },
    },
    created() {
      this.$store.dispatch('aboutUs/load').then((data) => {
        this.socials = getSocials(data)
      })
      this.$store.dispatch('restaurant/load').then((data) => {
        this.restaurants = data
      })
    }
  }
</script>

<style>

</style>
