<template>
  <div class="our-contact">
    <ul class="our-contact__list" v-if="settings && settings.contacts">
      <li class="our-contact__item" v-if="settings.contacts.phone">
        <a :href="`tel:${settings.contacts.phone}`" class="our-contact__link">
          <BaseSvg name="ico-phone"/>
          <span>{{ settings.contacts.phone }}</span>
        </a>
      </li>
      <li class="our-contact__item" v-if="settings.contacts.email">
        <a :href="`mailto:${settings.contacts.email}`" class="our-contact__link">
          <BaseSvg name="ico-email"/>
          <span>{{ settings.contacts.email }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import {mapState} from "vuex"
  export default {
    name: 'OurContact',
    computed: {
      ...mapState('settings', ['settings'])
    }
  }
</script>

<style>

</style>
