<template>
  <div class="page">
    <TheHeader class="page__header"/>
    <transition name="fade" @before-enter="onEnter" appear mode="out-in">
      <router-view class="page__main"/>
    </transition>

    <TheFooter class="page__footer"/>
    <TheSvgList/>

    <div class="page__to-top-wrap">
      <div class="container-inner">
        <button type="button"
                class="page__to-top"
                :class="{_active: showToTop}"
                @click.prevent="$scrollTo({x: 0, y: 0})"
        >
          <BaseSvg name="ico-arrow-down"/>
        </button>
      </div>
    </div>

    <LetItSnow v-if="isNewYearMod"
               v-bind="snowConf"
               :show="show"/>
  </div>
</template>
<script>
  import TheHeader from '../components/TheHeader'
  import TheFooter from '../components/TheFooter'
  import TheSvgList from '../components/TheSvgList'
  import LetItSnow from 'vue-let-it-snow'
  import {mapState, mapGetters} from 'vuex'

  export default {
    components: {
      TheSvgList,
      TheFooter,
      TheHeader,
      LetItSnow
    },
    data() {
      return {
        showToTop: false,
        timer: null,
        snowConf: {
          windPower : 1,
          speed : 2,
          count : 12,
          size : 10,
          opacity : 1,
          images: [
              require('@/assets/img/snow.png'),
          ]
        },
        show: false
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      ...mapGetters(['isNewYearMod']),
    },
    methods: {
      onEnter() {
        this.$root.$emit("beforeEnter");
      },
      checkScroll() {
        let isHalf = window.innerHeight / 2 <= window.scrollY
        if (isHalf) {
          if (!this.showToTop) {
            this.showToTop = true
          }
        } else {
          if (this.showToTop) {
            this.showToTop = false
          }
        }
      },
      onResize() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.checkScroll()
        }, 300)
      },
      checkInfoPopups() {
        let popups = this.$get(this.settings, 'info_popups', [])
        let popupsFromStorage = window.localStorage.getItem('infoPopups')
        popupsFromStorage = popupsFromStorage ? JSON.parse(popupsFromStorage) : {}

        const popup = popups.find(item => {
          return !popupsFromStorage.hasOwnProperty(item.slug) || (new Date(item.updated_at) > new Date(popupsFromStorage[item.slug]))
        })

        for (let key in popupsFromStorage) {
          if (!popupsFromStorage.hasOwnProperty(key)) continue
          if (!popups.some(item => item.slug === key)) {
            delete popupsFromStorage[key]
          }
        }
        window.localStorage.setItem('infoPopups', JSON.stringify(popupsFromStorage))

        if (popup) {
          setTimeout(() => {
            this.$vModal.open('info-popup', {popupData: popup})
            popupsFromStorage[popup.slug] = String(new Date().toISOString())
            window.localStorage.setItem('infoPopups', JSON.stringify(popupsFromStorage))
          }, 5000)
        }
      }
    },
    created() {
      window.addEventListener('scroll', this.checkScroll)
      window.addEventListener('resize', this.onResize)
      this.checkInfoPopups()
    },
    mounted() {
      this.show = true
      this.$nextTick(() => {
        this.checkScroll()
      })
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.checkScroll)
      window.removeEventListener('resize', this.onResize)
    }
  }
</script>

<style lang="less">
  .snow-wrap {
    z-index: 100!important;
  }
</style>
