import
  i18n,
{loadLanguageAsync} from '../../i18n'
import getLangObjByShowCode from '../../i18n/common/getLangObjByShowCode'
import getLangObjByCode from '../../i18n/common/getLangObjByCode'

export default async function (to, from, next) {
  let languageShowCode = to.params.locale
  let langObj = getLangObjByShowCode(languageShowCode)

  if (!langObj) {
    langObj = getLangObjByCode(i18n.locale)
    await loadLanguageAsync(langObj.code)
    return next(langObj.show_code)
  }

  await loadLanguageAsync(langObj.code)
  return next()
}