<template>
  <div class="our-location">
    <ul class="our-location__list">
      <li class="our-location__item"
          v-for="item in items"
          :key="item.id">
        <BaseSvg class="our-location__item-img" name="ico-location" width="2rem" height="3rem"/>
        <span class="page-footer__location-text">
          {{ item.address }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'OurLocation',
    props: {
      items: null
    }
  }
</script>

<style>

</style>
