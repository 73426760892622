<template>
  <div class="page-mob-accordion" :class="{_open: isOpen}">
    <div class="page-mob-accordion__title"
         @click.prevent="onOpen"
    >{{title}}</div>
    <CollapseTransition>
      <div v-if="isDesktop || isOpen">
        <slot></slot>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import {CollapseTransition} from 'vue2-transitions'
import windowSizeMixin from '@/mixins/windowSizeMixin'

export default {
name: "PageMobAccordion",
  mixins: [windowSizeMixin],
  props: {
    title: {
      data: Array,
      default: () => [],
    }
  },
  components: {
    CollapseTransition
  },
  data(){
    return {
      isOpen: false
    }
  },
  computed: {
    isDesktop() {
      return this.windowSizeMixin.width > 1023
    }
  },
  methods: {
    onOpen() {
      if (this.isDesktop) return
      return this.isOpen = !this.isOpen
    }
  }
}
</script>


<style>

</style>
