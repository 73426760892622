//'noindex, follow'

export function setMetaRobots(content, status, withoutNoPrerender) {
  if (!content) return
  const tagRobots = getTagRobots()
  tagRobots.setAttribute('content', content)

  if (withoutNoPrerender) {
    deleteTagPrerender()
  } else if (status) {
    const tagPrerender = getTagPrerender()
    tagPrerender.setAttribute('content', status)
  }

  // if (content.includes('noindex')) {
  //   removeCanonicalTag()
  // }
}

export function deleteMetaRobots() {
  const tag = document.head.querySelector('meta[name="robots"]')
  if (tag) document.head.removeChild(tag)
  deleteTagPrerender()
}

function getTagRobots() {
  return document.head.querySelector('meta[name="robots"]') ||
      createMetaTag('robots')
}

function getTagPrerender() {
  return document.head.querySelector('meta[name="prerender-status-code"]') ||
      createMetaTag('prerender-status-code')
}

function deleteTagPrerender() {
  const tag2 = document.head.querySelector('meta[name="prerender-status-code"]')
  if (tag2) document.head.removeChild(tag2)
}

function createMetaTag(name) {
  const tag = document.createElement('meta')
  tag.setAttribute('name', name)
  document.head.appendChild(tag)
  return tag
}
