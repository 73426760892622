<template>
  <router-view :key="key"/>
</template>

<script>
  import {mapState} from 'vuex'
  import {languages} from '@/i18n'

  export default {
    metaInfo() {
      return {
        ...this.metaComputed
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      key() {
        return this.$store.state.key
      },
      metaTitle() {
        return this.$get(this.settings, 'site.title')
      },
      metaSiteName() {
        return this.$get(this.settings, 'site.site_name')
      },
      metaDescription() {
        return this.$get(this.settings, 'site.description')
      },
      metaComputed() {
        const data = {
          link: [
            {
              vmid: 'canonical',
              rel: 'canonical',
              href: `${window.location.origin}/${this.$route.params.locale}`
            }
          ],
          meta: []
        }
        for (let langItem of languages) {
          data.link.push({
            vmid: `alternate_${langItem.code}`,
            rel: 'alternate',
            hreflang: langItem.code,
            href: `${window.location.origin}/${langItem.show_code}`
          })
        }
        if (this.metaSiteName) {
          data.titleTemplate = `%s | ${this.metaSiteName}`
          data.meta.push({
            vmid: 'og:site_name',
            property: 'og:site_name',
            content: this.metaSiteName
          })
        }

        if (this.metaTitle) {
          data.title = this.metaTitle
          data.meta.push({
                vmid: 'og:title',
                property: 'og:title',
                content: this.metaTitle,
                template: this.metaSiteName ? `%s | ${this.metaSiteName}` : ''
              },
              {
                vmid: 'twitter:title',
                name: 'twitter:title',
                content: this.metaTitle,
                template: this.metaSiteName ? `%s | ${this.metaSiteName}` : ''
              })
        }

        if (this.metaDescription) {
          data.meta.push({
                vmid: 'description',
                name: 'description',
                content: this.metaDescription
              },
              {
                vmid: 'twitter:description',
                name: 'twitter:description',
                content: this.metaDescription
              },
              {
                vmid: 'og:description',
                property: 'og:description',
                content: this.metaDescription
              })
        }
        return data
      }
    },
    //methods: {
    //  ...mapActions({
    //    loadSettings: 'settings/load'
    //  }),
    //  load() {
    //    this.loadSettings().then(() => {
    //      setTimeout(() => {
    //        removePagePreloader()
    //      }, 500)
    //    })
    //  }
    //},
    //mounted() {
    //  this.load()
    //},
    //watch: {
    //  key() {
    //    console.log('update')
    //    setPagePreloader()
    //    this.load()
    //  }
    //}
  }
</script>

