export default {
  namespaced: true,
  state: {
    tags: [],
    totalCount: 6,
  },
  mutations: {
    setValueTotalCount(state, payload) {
      state.totalCount = payload
    },
    setTags(state, payload) {
      const hasTag = state.tags.find(item => item.id === payload.id)
      if(!hasTag) {
        state.tags.push(payload)
      }

      state.totalCount -= 1
    },
    setRemoveTags(state, payload) {
      const newTags = state.tags.filter(item => {
        if(item.id !== payload.id) {
          return item
        }
      })

      state.tags = newTags
      state.totalCount += payload.count
    },
    setUpdateCountTag(state, payload) {
      const tags = state.tags.map(item => {
        if(item.id === payload.id) {
          item.count = item.count + 1
        }
        return item
      })

      state.totalCount -= 1
      state.tags = tags
    },
    setTotalCount(state, payload) {
      state.totalCount += payload
    },
    decrementCount(state, { id, count}) {
      const updateTags = state.tags.map(item => {
        if(item.id === id) {
          return {...item, count}
        }
        return item
      })

      state.tags = updateTags
      state.totalCount += 1
    },
    setRemoveAllTags(state) {
      state.tags = []
    }
  },
  actions: {
    addTags({ commit }, payload) {
      commit('setTags', payload)
    },
    removeTag({ commit }, payload) {
      commit('setRemoveTags', payload)
    },
    updatedCount({ commit }, payload) {
      commit('setUpdateCountTag', payload)
    }
  }
}
