import {http} from '@/axios'

function getInitState() {
  return {
    pending: false,
    promiseCategories: null,
    loaded: false,
    listCategories: []
  }
}

export default {
  namespaced: true,
  state: getInitState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getInitState())
    },
    setProperty(state, [key, value]) {
      if (!state.hasOwnProperty(key)) return
      state[key] = value
    },
  },
  actions: {
    load({state, commit}) {
      if (state.loaded) return Promise.resolve(state.listCategories)

      if (state.pending) {
        return state.promiseCategories
      }
      commit('setProperty', ['pending', true])
      const promiseCategories = http.get('/api/categories').then(({data}) => {
        commit('setProperty', ['listCategories', data.data])
      }).finally(() => {
        commit('setProperty', ['loaded', true])
        commit('setProperty', ['pending', false])
        commit('setProperty', ['promiseCategories', null])
      })

      commit('setProperty', ['promiseCategories', promiseCategories])

      return promiseCategories
    },
  },
}
