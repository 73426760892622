import routesAccount from "./routesAccount";
const E404 = () => import(/* webpackChunkName: "e404" */ "../views/E404");
const HomePage = () => import(/* webpackChunkName: "home-page" */ "../views/HomePage");
const CatalogProducts = () => import(/* webpackChunkName: "home-page" */ "../components/CatalogProducts");
const Guide = () => import(/* webpackChunkName: "guide" */ "../views/Guide");
const EventsListPage = () => import(/* webpackChunkName: "events" */ "../views/EventsListPage");
const EventPage = () => import(/* webpackChunkName: "events" */ "../views/EventPage");
const Basket = () => import(/* webpackChunkName: "basket" */ "../views/Basket");
const ProductPage = () => import(/* webpackChunkName: "product" */ "../views/ProductPage");
const Contacts = () => import(/* webpackChunkName: "contacts" */ "../views/Contacts");
const Delivery = () => import(/* webpackChunkName: "delivery" */ "../views/Delivery");
const AboutUs = () => import(/* webpackChunkName: "about" */ "../views/AboutUs");
const Loyalty = () => import(/* webpackChunkName: "loyalty" */ "../views/Loyalty");
const Policy = () => import(/* webpackChunkName: "public-static-content" */ "../views/Policy");
const Feedback = () => import(/* webpackChunkName: "public-static-content" */ "../views/Feedback.vue");
const PublicOffer = () => import(/* webpackChunkName: "public-static-content" */ "../views/PublicOffer");
const AuthRestorePasswordPage = () =>
  import(/* webpackChunkName: "password-restore" */ "../views/AuthRestorePasswordPage");
const PDFMenu = () => import(/* webpackChunkName: "PDFMenu" */ "../views/PDFMenu");

const routes = [
  {
    path: "",
    component: HomePage,
    children: [
      {
        path: "",
        name: "home",
        component: CatalogProducts,
      },
      {
        path: "category/:categoryId",
        name: "category",
        component: CatalogProducts,
      },
    ],
  },
  {
    path: "events/:categoryId?",
    name: "events-list",
    component: EventsListPage,
  },
  {
    path: "events/:categoryId/:id",
    name: "event",
    component: EventPage,
  },
  {
    path: "product/:id",
    name: "product",
    component: ProductPage,
  },
  {
    path: "basket",
    name: "basket",
    component: Basket,
  },
  {
    path: "contacts",
    name: "contacts",
    component: Contacts,
  },
  {
    path: "delivery",
    name: "delivery",
    component: Delivery,
  },
  {
    path: "about-us",
    name: "about-us",
    component: AboutUs,
  },
  {
    path: "loyalty",
    name: "loyalty",
    component: Loyalty,
    meta: {
      pageId: 11,
    },
  },
  {
    path: "pdf-menu",
    name: "pdf-menu",
    component: PDFMenu,
    meta: {
      pageId: 12,
    },
  },
  {
    path: "policy",
    name: "policy",
    component: Policy,
    meta: {
      pageId: 4,
    },
  },
  {
    path: "public-offer",
    name: "public-offer",
    component: PublicOffer,
    meta: {
      pageId: 3,
    },
  },
  {
    path: "feedback",
    name: "feedback",
    component: Feedback,
  },
  {
    path: "change-password",
    name: "auth-change-password",
    component: AuthRestorePasswordPage,
  },
  ...routesAccount,
];

if (process.env.NODE_ENV !== "production") {
  routes.push({
    path: "guide",
    name: "guide",
    component: Guide,
  });
}

routes.push({
  path: "*",
  name: "E404",
  component: E404,
});

export default routes;
