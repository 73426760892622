export default function getSocials(obj) {
  const result = []
  if (!obj || !obj.social_networks) return []
  obj = JSON.parse(obj.social_networks)
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      result.push({
        key: key,
        link: obj[key].trim(),
        iconName: `ico-${key}`
      })
    }
  }

  return result
}
