import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {http} from '@/axios'
import store from '@/store'
import getLocale from './common/getLocale'
import setLocaleToBrowserStorage from './common/setLocaleToBrowserStorage'
import getLangObjByCode from './common/getLangObjByCode'

const {languages, defaultLanguageCode} = SETTINGS.LANGUAGES
const languagesCodes = languages.map(language => language.code)
const loadedLanguages = [] // our default language that is preloaded

Vue.use(VueI18n)


const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: defaultLanguageCode,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  missing: (a, b) => {
   console.log('%c%s', 'color: #d0a53e; font: 16px/1 Tahoma;', b)
  }
})

setI18nLanguage(i18n.locale)

function setI18nLanguage(code) {
  if(!languagesCodes.includes(code)) return
  const langObj = getLangObjByCode(code)

  if(i18n.locale !== code) {
    i18n.locale = code
    store.dispatch('reset')
  }

  document.querySelector('html')
      .setAttribute('lang', `${code}-${langObj.country_code}`)
  setLocaleToBrowserStorage(code)
  return code
}

function loadLanguageAsync(code) {
  if(!code) {
    code = i18n.locale
  }

  if(!languagesCodes.includes(code)) return

  // If the language was already loaded
  if (loadedLanguages.includes(code)) {
    return Promise.resolve(setI18nLanguage(code))
  }

  store.commit('setPending', true)
  // If the language hasn't been loaded yet
  return http.get(`/api/language/translations`, {
    params: {
      lang: code
    }
  }).then(({data}) => {
        i18n.setLocaleMessage(code, data.data)
        loadedLanguages.push(code)
        return setI18nLanguage(code)
      }
  ).finally(() => {
    store.commit('setPending', false)
  })
}

export {languages, setI18nLanguage, loadLanguageAsync}
export default i18n
