import {http, baseURL} from '@/axios'
import Echo from 'laravel-echo'
import Vue from 'vue'
import router from '@/router'

window.Pusher = require('pusher-js')
// window.Pusher.logToConsole = true
let pusher = null

export default {
  namespaced: true,
  state: {
    pending: false,
    pendingMore: false,
    data: [],
    loadedPages: [],
    totalPages: 1,
    unread_count: 0,
    promiseLoading: null,
    pusherChannel: null,
    notificationsTotal: 0,
    firstLoad: true
  },
  mutations: {
    setProperty(state, [key, value]) {
      if (!state.hasOwnProperty(key)) return
      state[key] = value
    }
  },
  actions: {
    load({state, commit, dispatch}, payload) {
      if (state.promiseLoading) {
        return state.promiseLoading
      }

      const {
        currentPage,
        isMore
      } = payload || {}

      if (!payload) {
        commit('setProperty', ['data', []])
        commit('setProperty', ['loadedPages', []])
        commit('setProperty', ['totalPages', 1])
      }

      if (isMore) {
        commit('setProperty', ['pendingMore', true])
      } else {
        commit('setProperty', ['pending', true])
      }
      const promiseLoading = http.get('/api/user/notifications/history', {
        params: {
          page: currentPage || 1,
          limit: 20,
        },
      }).then(({data}) => {
        if (isMore) {
          commit('setProperty', ['data', state.data.concat(data.data)])
          state.loadedPages.push(currentPage || 1)
        } else {
          commit('setProperty', ['data', data.data])
          commit('setProperty', ['loadedPages', [currentPage || 1]])
        }
        commit('setProperty', ['unread_count', data.unread_count])
        commit('setProperty', ['totalPages', data.meta.last_page])
        commit('setProperty', ['notificationsTotal', data.meta.total])
        dispatch('enablePusher')
      }).finally(() => {
        commit('setProperty', ['pending', false])
        commit('setProperty', ['pendingMore', false])
        commit('setProperty', ['promiseLoading', null])
        commit('setProperty', ['firstLoad', false])
      })

      commit('setProperty', ['promiseLoading', promiseLoading])

      return promiseLoading
    },
    enablePusher({state, commit, rootState, dispatch}) {
      if (!state.pusherChannel) {
        const pusherChannel = `App.User.${rootState.user.profile.id}`

        pusher = new Echo({
          broadcaster: 'pusher',
          key: rootState.settings.settings.global.pusher.public_key,
          cluster: rootState.settings.settings.global.pusher.cluster,
          forceTLS: true,
          namespace: 'App.Events.WebPush',
          authEndpoint: baseURL + '/api/broadcasting/auth',
          auth: {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Token': rootState.user.token,
            }
          }
        })

        pusher.private(pusherChannel)
            .listen('WebPushNotificationEvent', (e) => {
              dispatch('load')

              if (router.currentRoute.name === 'account-messages' && router.currentRoute.query.page) {
                router.replace({name: router.currentRoute.name}).catch(() => {})
              }
              Vue.prototype.$toastr.Add({
                msg: e.push.message.body || e.push.message.message,
                title: e.push.header,
                type: 'info',
                clickClose: true,
                onClicked: () => {
                  if (router.currentRoute.name !== 'account-messages') {
                    router.push({name: 'account-messages', query: {id: e.push.id}})
                  }
                },
              })
              commit('setProperty', ['unread_count', e.unreadCount])
            })
        commit('setProperty', ['pusherChannel', pusherChannel])
      }
    },
    disablePusher({state, commit}) {
      if (state.pusherChannel) {
        pusher.leave(state.pusherChannel)
        commit('setProperty', ['pusherChannel', null])
      }
    },
    loadUnread({commit}) {
      http.get('/api/user/notifications/unread-count').then(({data}) => {
        commit('setProperty', ['unread_count', data.data.count])
      })
    },
    markAsRead({dispatch}, notificationId) {
      if (!notificationId) return
      return http.put(`/api/user/notifications/${notificationId}/mark-as-seen`).then(() => {
        dispatch('loadUnread')
      })
    }
  }
}