<template>
  <div class="mob-apps" v-if="appsList && (appsList.ios || appsList.android)">
    <h4 class="mob-apps__title" v-if="showTitle">{{ $t('Завантажуйте наш додаток') }}</h4>
    <div class="mob-apps__list">
      <a v-if="appsList.ios"
         target="_blank"
         :href="appsList.ios" class="mob-apps__link">
        <img src="../assets/img/svg/app-store.svg" alt="ios">
      </a>
      <a v-if="appsList.android"
         target="_blank"
         :href="appsList.android" class="mob-apps__link">
        <img src="../assets/img/svg/google-play.svg" alt="android">
      </a>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'MobApps',
    props: {
      showTitle: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapState({
        appsList(state) {
          return state.settings.settings && state.settings.settings.mobile_application || {}
        }
      })
    },
  }
</script>

<style>

</style>
