import {http} from '@/axios'

function getInitState() {
  return {
    pendingRestaurant: false,
    promiseRestaurant: null,
    loaded: false,
    restaurant_data: []
  }
}

export default {
  namespaced: true,
  state: getInitState(),
  getters: {
    restaurantsCount(state) {
      return state && state.restaurant_data && state.restaurant_data.length || 0
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getInitState())
    },
    setProperty(store, [key, value]) {
      store[key] = value
    }
  },
  actions: {
    load({state, commit}) {
      if (state.loaded) return Promise.resolve(state.restaurant_data)

      if (state.pendingRestaurant) {
        return state.promiseRestaurant
      }

      commit('setProperty', ['pendingRestaurant', true])

      const promiseRestaurant = http.get(`/api/restaurants`)
        .then(({data}) => {
          commit('setProperty', ['restaurant_data', data.data])
          return Promise.resolve(data.data)
        })
        .finally(() => {
          commit('setProperty', ['loaded', true])
          commit('setProperty', ['pendingRestaurant', false])
          commit('setProperty', ['promiseRestaurant', false])
        })

      commit('setProperty', ['promiseRestaurant', promiseRestaurant]);

      return promiseRestaurant
    }
  }
}
