const {languages, defaultLanguageCode} = SETTINGS.LANGUAGES

export default function (code) {
  let langObj = languages.find(item => item.code === code)

  if (!langObj) {
    langObj = languages.find(item => item.code === defaultLanguageCode)
  }

  return langObj
}