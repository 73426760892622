<template>
  <transition name="fade">
    <section
      v-if="isOpen"
      @mousedown="onMousedown"
      @mouseup="onMouseup"
      :style="style"
      class="modal"
      :class="{'_no-head': !title, '_current': isCurrent, '_not-current': !isCurrent}"
    >
      <div ref="content" class="modal__content-wrap">
        <button @click.prevent="close" class="modal__close">
          <BaseSvg name="ico-close" />
        </button>
        <div class="modal__content">
          <slot name="content" v-bind="passThroughProps">
            <slot name="header" v-bind="passThroughProps">
              <div class="modal__head" v-if="title">
                <BaseSvg
                  v-if="icoFormatted"
                  :name="icoFormatted.name"
                  :width="icoFormatted.width"
                  :height="icoFormatted.height"
                  class="modal__title-ico"
                />
                <h2 class="modal__title">
                  <slot name="title" v-bind="passThroughProps">{{ title }}</slot>
                </h2>
              </div>

            </slot>
            <div class="modal__main">
              <slot v-bind="passThroughProps">No Data</slot>
            </div>
          </slot>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      title: {
        type: String,
      },
      ico: {
        type: [Object, String],
      },
    },
    data() {
      return {
        pressTarget: false,
      };
    },
    computed: {
      isOpen() {
        return !!this.$root.modals[this.name];
      },
      currentModalObj() {
        let modals = this.$root.modals;

        return Object.keys(modals).find((key) => {
          return key === this.name;
        });
      },
      icoFormatted() {
        let ico = null;

        if (typeof this.ico === "string") {
          ico = {
            name: this.ico,
          };
        } else {
          ico = this.ico;
        }

        if (typeof ico !== "object") return null;

        return ico;
      },
      passThroughProps() {
        return this.$root.modals[this.name] && this.$root.modals[this.name].props;
      },
      style() {
        if (!this.isOpen) return null;

        return {
          zIndex: this.currentModalObj.zIndex,
        };
      },
      isCurrent() {
        if (!this.$root.lastModalsNames.length) return;

        let name = this.$root.lastModalsNames[
        this.$root.lastModalsNames.length - 1
          ];
        return this.name === name;
      },
    },
    methods: {
      onMousedown(event) {
        if (!this.$refs.content) return;
        this.pressTarget = event.target.contains(this.$refs.content);
      },
      onMouseup(event) {
        if (this.pressTarget && event.target.contains(this.$refs.content))
          this.close();
      },
      close() {
        this.$emit("close");
        this.$vModal.close(this.name);
        return false;
      },
    },
    watch: {
      isOpen(value) {
        if (value) {
          this.$vModal.blockScroll();
        } else {
          this.$vModal.unBlockScroll();
        }
      },
    },
  };
</script>

<style lang="less">
</style>
