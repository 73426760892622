import i18n from '@/i18n'

export default function (price) {
  if (!price) return 0
  let priceTransformed = roundToTwo(price).toFixed(2)
  if ((+priceTransformed ^ 0) === +priceTransformed) {
    priceTransformed = Number(price).toFixed(0)
  }

  switch (i18n.locale) {
    case 'en':
      return priceTransformed.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    default:
      return priceTransformed.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }
}

function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2')
}
