import {http} from '@/axios'

function getInitState() {
  return {
    pending: false,
    loaded: false,
    promise: null,
    data: {}
  }
}

export default {
  namespaced: true,
  state: getInitState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getInitState())
    },
    setProperty(state, [key, value]) {
      if (!state.hasOwnProperty(key)) return
      state[key] = value
    }
  },
  actions: {
    load({state, commit}) {
      if (state.loaded) return Promise.resolve(state.data)

      if (state.pending) {
        return state.promise
      }

      commit('setProperty', ['pending', true])

      const promise = http.get('/api/page/1').then(({data}) => {
        commit('setProperty', ['data', data.item])
        return Promise.resolve(data.item)
      }).finally(() => {
        commit('setProperty', ['loaded', true])
        commit('setProperty', ['pending', false])
        commit('setProperty', ['promise', null])
      })

      commit('setProperty', ['promise', promise])

      return promise
    },
  },
}
