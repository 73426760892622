import Vue from 'vue';
import formatDate, {formatDateForTimeTag} from '../helpers/formatDate';

Vue.filter('formatDate', function (value) {
  return formatDate(value);
});

Vue.filter('formatDateForTimeTag', function (value) {
  return formatDateForTimeTag(value);
});
