import {mainApp} from "@/main";
import VueScrollTo from 'vue-scrollto'

export default function (to, from, savedPosition) {
  if(to.params.savePosition) return null
  return new Promise(resolve => {
    if (to.hash) {
      setTimeout(() => {
        VueScrollTo.scrollTo(to.hash)
      }, 500)
      return resolve(to)
    }

    mainApp.$root.$once("beforeEnter", () => {
      if (savedPosition) {
        setTimeout(() => {
          resolve(savedPosition)
        }, 500)
      } else {
        resolve({ x: 0, y: 0 });
      }
    });
  });
}

