<template>
  <div style="width: 0; height: 0;">
    <v-modal v-if="usedModals.includes('auth-login')"
             name="auth-login"
             :title="$t('Вхід')">
      <template v-slot="{redirect}">
        <AuthLogin :redirect="redirect"/>
      </template>
    </v-modal>
    <v-modal v-if="usedModals.includes('auth-registration')"
             name="auth-registration"
             :title="$t('Реєстрація')">
      <template>
        <AuthRegistration/>
      </template>
    </v-modal>
    <v-modal v-if="usedModals.includes('auth-reset-password')"
             name="auth-reset-password"
             :title="$t('Відновлення паролю')">
      <template>
        <AuthResetPassword/>
      </template>
    </v-modal>
    <v-modal v-if="usedModals.includes('basket-message')"
             name="basket-message">
      <template>
        <BasketMessage/>
      </template>
    </v-modal>
    <v-modal v-if="usedModals.includes('message')"
             name="message">
      <template v-slot="{ title, message, type, btnText, callBack }">
        <MessageModal
            :type="type"
            :title="title"
            :message="message"
            :btnText="btnText"
            :callBack="callBack"
        />
      </template>
    </v-modal>
    <v-modal v-if="usedModals.includes('buy-product')"
             name="buy-product">
      <template>
        <BuyProductModal/>
      </template>
    </v-modal>
    <v-modal v-if="usedModals.includes('modal-contacts')"
             name="modal-contacts"
             :title="$t('Інформація про доставку')">
      <template>
        <ModalContacts/>
      </template>
    </v-modal>
    <v-modal v-if="usedModals.includes('call-back')"
             name="call-back"
             :title="$t('Передзвонити?')">
      <template>
        <CallBack/>
      </template>
    </v-modal>
    <v-modal v-if="usedModals.includes('info-popup')"
             class="_no-padding _xs"
             name="info-popup">
      <template v-slot="{popupData}">
        <InfoPopup :popupData="popupData"/>
      </template>
    </v-modal>

  </div>
</template>

<script>
  const InfoPopup = () => import(/* webpackChunkName: "infoPopup" */ './InfoPopup');
  const AuthLogin = () => import(/* webpackChunkName: "auth" */ './AuthLogin');
  const AuthRegistration = () => import(/* webpackChunkName: "auth" */ './AuthRegistration');
  const AuthResetPassword = () => import(/* webpackChunkName: "auth" */ './AuthResetPassword');
  const BasketMessage = () => import(/* webpackChunkName: "basket" */ './BasketMessage');
  const MessageModal = () => import(/* webpackChunkName: "message" */ './MessageModal');
  const BuyProductModal = () => import(/* webpackChunkName: "basket" */ './BuyProductModal');
  const ModalContacts = () => import(/* webpackChunkName: "contacts" */ './ModalContacts');
  const CallBack = () => import(/* webpackChunkName: "callback" */ './CallBack');

  export default {
    components: {
      InfoPopup,
      AuthLogin,
      AuthRegistration,
      AuthResetPassword,
      BasketMessage,
      MessageModal,
      BuyProductModal,
      ModalContacts,
      CallBack
    },
    computed: {
      usedModals() {
        return this.$root.usedModals;
      }
    }
  };
</script>

<style lang="less">
</style>
