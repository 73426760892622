import Vue from 'vue'

const oneHourInMS = 1000 * 60 * 60

function createId() {
  return 'f' + +(new Date())
}

export default {
  namespaced: true,
  state: {
    basket: getBasketFromLocalStorage()
  },
  mutations: {
    setProperty(state, [key, value]) {
      if (!state.hasOwnProperty(key)) return
      Vue.set(state, key, value)
    },
    addProduct(state, product) {
      state.basket.push(product)
    },
    deleteProduct(state, specificId) {
      const index = state.basket.findIndex(item => item.specificId === specificId)
      Vue.delete(state.basket, index)
    },
    changeProductQuantity(state, {specificId, newQuantity}) {
      const index = state.basket.findIndex(item => item.specificId === specificId)
      Vue.set(state.basket[index], 'quantity', newQuantity)
    },

  },
  actions: {
    addProductToBasket({state, commit}, {product, quantity, variant, property, additional_ingredients, comment}) {
      const basket = state.basket
      const productInBasketIndex = findProductIndexByProps(basket, product.id, additional_ingredients, variant, property, comment)
      const productInBasket = basket[productInBasketIndex]

      if (productInBasketIndex < 0) {
        // IF PRODUCT NOT FOUND IN BASKET
        const newBasketItem = {
          product: JSON.parse(JSON.stringify(product)),
          quantity,
          variant,
          property,
          additional_ingredients: JSON.parse(JSON.stringify(additional_ingredients)),
          comment,
          specificId: createId()
        }

        commit('addProduct', newBasketItem)
      } else {
        // IF PRODUCT FOUND IN BASKET

        commit('changeProductQuantity', {
          specificId: productInBasket.specificId,
          newQuantity: productInBasket.quantity + quantity
        })
      }
    },
    clearBasket({dispatch}) {
      removeBasketFromLocalStorage()
      dispatch('loadBasket')
    },
    subscribe({state}) {
      this.subscribe((mutation) => {
        if (mutation.type.startsWith('basket/')) {
          setBasketToLocalStorage(state.basket)
        }
      })
    },
    loadBasket({commit}) {
      commit('setProperty', ['basket', getBasketFromLocalStorage()])
    }
  }
}

function findProductIndexByProps(products, productId, additional_ingredients, variant, property, comment) {
  return products.findIndex(item => {
    if (
        item.product.id !== productId
        || item.variant.size !== variant.size
        || (item.property && item.property.id) !== (property && property.id)
        || item.comment !== comment
    ) {
      return false
    }

    return isSameIngredients(additional_ingredients, item.additional_ingredients)
  })
}

function isSameIngredients(ingredients, ingredients2) {
  if (!ingredients) ingredients = []
  if (!ingredients2) ingredients2 = []
  ingredients = Object.values(ingredients)
  ingredients2 = Object.values(ingredients2)

  if (ingredients.length !== ingredients2.length) return false

  return !ingredients.find(item => {
    const id = item.ingredient.id
    const quantity = item.quantity

    return !ingredients2.find(item2 => {
      return item2.ingredient.id === id && item2.quantity === quantity
    })
  })
}

// LOCAL STORAGE

function setBasketToLocalStorage(products) {
  if (!Array.isArray(products)) return

  const result = {
    created: new Date(),
    products
  }

  window.localStorage.setItem('basket', JSON.stringify(result))
}

function getBasketFromLocalStorage() {
  let result = window.localStorage.getItem('basket')

  if (!result) return []

  result = JSON.parse(result) || {}

  const createdDate = new Date(result.created)
  const minDate = new Date() - oneHourInMS * 12

  return createdDate > minDate ? result.products : []
}

function removeBasketFromLocalStorage() {
  localStorage.removeItem('basket')
}
