<template>
  <header class="page-header" :class="isOpen ? '_open' : null">
    <div class="page-header__wrap">
      <div class="page-header__panel container">
        <div class="page-header__panel-inner container-inner">
          <div class="page-header__col">
            <div class="page-header__group">
              <button
                  type="button"
                  class="page-header__group-item page-header__menu-btn"
                  :class="isOpen ? '_open' : null"
                  @click.prevent="onMenuToggle"
              >
                <span class="icons">
                  <BaseSvg name="ico-burger" class="burger"/>
                  <BaseSvg name="ico-close" class="close"/>
                </span>
                <span class="page-header__menu-btn-text d-md-none">{{
                    $t('Меню')
                  }}</span>
              </button>
              <a v-if="settings && settings.contacts"
                 :href="`tel:${settings.contacts.phone}`"
                 class="page-header__group-item page-header__link _tel d-sm-none"
              >
                <BaseSvg name="ico-phone" width="2rem" height="2rem"/>
                <span class="page-header__link-text">
                  {{ settings.contacts.phone }}
                </span>
              </a>
            </div>
          </div>
          <div class="page-header__col">
            <div v-if="$route.name === 'home'" class="page-header__logo">
              <img src="../assets/img/svg/logo-text.svg" alt="Solo Pizza">
              <span v-if="isNewYearMod"
                    class="page-header__logo-hat"
                    :style="`background-image: url(${require('@/assets/img/svg/santa_hat.svg')})`"></span>
            </div>
            <router-link
                v-else
                :to="{ name: 'home'}"
                @click.native="$scrollTo({x: 0, y: 0})"
                class="page-header__logo"
            >
              <img src="../assets/img/svg/logo-text.svg" alt="Solo Pizza">
              <span v-if="isNewYearMod"
                    class="page-header__logo-hat"
                    :style="`background-image: url(${require('@/assets/img/svg/santa_hat.svg')})`"></span>
            </router-link>
          </div>
          <div class="page-header__col">
            <div class="page-header__group">
              <router-link
                  :to="{ name: 'basket', params: { basket_data: basket } }"
                  class="page-header__group-item page-header__link _basket"
              >
                <BaseSvg name="ico-cart" width="2rem" height="2rem"/>
                <span class="page-header__link-label "
                      :class="{'_hide': basketItemsCounter < 1 && !giftedProductsCount,
                      '_yellow': giftedProductsCount && basketItemsCounter < 1}"
                >
                  <BaseSvg v-if="giftedProductsCount && basketItemsCounter < 1"
                           name="ico-gift"/>
                  <span v-else>{{ basketItemsCounter }}</span>
                </span>
              </router-link>
              <!--<span-->
              <!--    class="page-header__group-item page-header__link"-->
              <!--    @click="openModal"-->
              <!--&gt;-->
              <!--  <BaseSvg-->
              <!--      name="ico-delivery"-->
              <!--      width="3rem"-->
              <!--      height="3rem"-->
              <!--      class="page-header__icon-delivery"-->
              <!--  />-->
              <!--</span>-->
              <router-link :to="{name: 'delivery'}"
                           class="page-header__group-item page-header__link"
              >
                <BaseSvg
                    name="ico-delivery"
                    width="3rem"
                    height="3rem"
                />
              </router-link>
              <router-link
                  v-if="isLogged"
                  :to="{ name: 'account' }"
                  class="page-header__group-item page-header__link"
              >
                <!--<span v-if="unread_count"-->
                <!--      class="page-header__link-label _bell"-->
                <!--&gt;-->
                <!--  <BaseSvg-->
                <!--      name="ico-bell"/>-->
                <!--</span>-->
                <span class="page-header__link-ico-wrap">
                  <BasePreloader v-if="pendingAuth"/>
                  <BaseSvg name="ico-person" width="2rem" height="2rem"/>
                </span>
                <span class="page-header__link-text d-md-none">
                  {{ $t('Кабінет') }}
                </span>
              </router-link>
              <button
                  v-else
                  @click.prevent="openLogin"
                  class="page-header__group-item page-header__link"
              >
                <BaseSvg name="ico-person" width="2rem" height="2rem"/>
                <span class="page-header__link-text d-md-none">{{
                    $t('Вхід')
                  }}</span>
              </button>
              <router-link v-if="isLogged"
                           :to="{name: 'account-messages'}"
                           class="page-header__group-item page-header__link _notifications"
              >
                <span class="page-header__link-label" :class="{_hide: !unread_count}">
                  {{ unread_count }}
                </span>
                <BaseSvg
                    name="ico-bell-stroke"
                    width="2rem" height="2rem"
                />
              </router-link>
              <LangSelect
                  class="page-header__group-item d-sm-none"
                  :languages="languages"
              />
            </div>
          </div>
        </div>
      </div>
      <transition name="dropdown-big">
        <div v-if="isOpen" v-click-out="onMenuClose" class="page-header__menu">
          <div class="page-header__menu-content container">
            <div class="page-header__menu-inner container-inner">
              <div class="page-header__menu-group">
                <div class="categories">
                  <div class="categories__btn-wrap"
                       v-for="(item, index) in listCategories"
                       :key="index">
                    <router-link
                        :to="{
                      name: 'category',
                      params: {categoryId: item.slug || item.id },
                      hash: '#catalog',
                    }"
                        :class="{
                      _active: isActiveLink(index, item.slug || item.id),
                    }"
                        class="categories__btn"
                    >
                      <img :src="$imgPlaceholder"
                           :data-src="item.image_xs"
                           class="categories__image lazyload"
                           :alt="item.name"
                      />
                      <span :title="item.name">
                        <span>{{ item.name }}</span>
                      </span>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="page-header__menu-group">
                <ul class="page-header__menu-list">
                  <template v-for="(item, index) in menuLinks">
                    <li :key="index"
                        v-if="!item.pageId || $get(settings, 'pages', []).some(page => page === item.pageId)"
                        class="page-header__menu-list-item"
                    >
                      <router-link
                          :to="{ name: item.nameUrl, params: item.params}"
                          class="page-header__menu-list-link"
                      >{{ $t(item.name) }}
                      </router-link
                      >
                    </li>
                  </template>
                </ul>
              </div>
              <div class="page-header__menu-footer d-none d-sm-flex">
                <a v-if="settings && settings.contacts"
                   :href="`tel:${settings.contacts.phone}`"
                   class="page-header__menu-footer-item page-header__link _tel"
                >
                  <BaseSvg name="ico-phone" width="2rem" height="2rem"/>
                  <span class="page-header__link-text">{{
                      settings.contacts.phone
                    }}</span>
                </a>
                <div
                    class="page-header__menu-footer-item page-header__lang-mob"
                >
                  <router-link
                      v-for="language in languages"
                      :key="language.id"
                      :to="{ params: { locale: language.show_code } }"
                      class="page-header__lang-mob-item"
                  >
                    {{ language.show_code }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
  import {mapState, mapActions, mapGetters} from 'vuex'
  import LangSelect from './LangSelect'
  import {languages} from '@/i18n'

  export default {
    name: 'TheHeader',
    components: {
      LangSelect,
    },
    data() {
      return {
        isOpen: false,
        modalOpen: false,
        delivery: {},
        languages
      }
    },
    computed: {
      ...mapState('categories', ['listCategories']),
      ...mapState('navigation', ['menuLinks']),
      ...mapState('basket', ['basket']),
      ...mapState('user', ['pendingAuth']),
      ...mapState('user', {
        giftedProductsCount(state) {
          return this.$get(state, 'profile.gifted_products.product_list', []).length
        }
      }),
      ...mapState('settings', ['settings']),
      ...mapGetters(['isNewYearMod']),

      basketItemsCounter() {
        return this.basket.reduce(
            (accumulator, current) => (accumulator += current.quantity),
            0
        )
      },
      isLogged() {
        return this.$store.getters['user/isLogged']
      },
      ...mapState('notifications', [
        'unread_count'
      ]),
    },
    async created() {
      await this.load()
    },
    methods: {
      ...mapActions('categories', ['load']),
      onMenuToggle() {
        this.isOpen = !this.isOpen
      },
      onMenuClose() {
        this.isOpen = false
      },
      openModal() {
        this.$vModal.open('modal-contacts')
      },
      openLogin() {
        this.$vModal.open('auth-login', {
          redirect: {name: 'account'}
        })
      },
      isActiveLink(index, id) {
        return (
            (this.$route.name === 'home' || this.$route.name === 'category') && (index === 0 && !this.$route.params.categoryId) || id == this.$route.params.categoryId
        )
      },
    },
    watch: {
      $route() {
        this.onMenuClose()
      },
    },
  }
</script>

<style>
</style>

