import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import basket from './modules/basket'
import categories from './modules/categories'
import product from './modules/product'
import navigation from './modules/navigation'
import restaurant from './modules/restaurant'
import citiesList from './modules/citiesList'
import settings from './modules/settings'
import aboutUs from './modules/aboutUs'
import banners from './modules/banners'
import notifications from './modules/notifications'

let preloader = document.querySelector('.preloader-page')

import {
  DELIVERY_METHOD_ADDRESS,
  DELIVERY_METHOD_SELF
} from '@/constants/deliveryMethods'

import {
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_ONLINE,
  PAYMENT_METHOD_WITH_TERMINAL
} from '@/constants/paymentMethods'

import {
  AUTH_METHOD_PHONE,
  AUTH_METHOD_EMAIL
} from '@/constants/authMethods'

const deliveryMethods = [
  {
    id: DELIVERY_METHOD_ADDRESS,
    name: 'Доставка за адресою',
  },
  {
    id: DELIVERY_METHOD_SELF,
    name: 'Самовивіз',
  }
]

const paymentMethods = [
  {
    id: PAYMENT_METHOD_CASH,
    name: 'Оплата готівкою',
  },
  {
    id: PAYMENT_METHOD_ONLINE,
    name: 'Оплата онлайн',
  },
  {
    id: PAYMENT_METHOD_WITH_TERMINAL,
    name: 'Оплата терміналом',
  }
]

const authMethods = [
  {
    id: AUTH_METHOD_PHONE,
    name: 'Телефон',
  },
  {
    id: AUTH_METHOD_EMAIL,
    name: 'Email',
  }
]

const genderList = [
  {
    id: 'male',
    name: 'Чоловік'
  },
  {
    id: 'female',
    name: 'Жінка'
  }
]

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    settings,
    user,
    basket,
    categories,
    product,
    navigation,
    restaurant,
    citiesList,
    aboutUs,
    banners,
    notifications
  },
  state: {
    pending: true,
    key: 1,
    deliveryMethods,
    paymentMethods,
    authMethods,
    genderList
  },
  getters: {
    isNewYearMod() {
      let now = new Date()
      let from = new Date().setMonth(11, 1)
      let to = new Date().setMonth(0, 20)
      return now < to || now > from
    }
  },
  mutations: {
    reset(state) {
      state.key += 1
    },
    setPending(state, value) {
      state.pending = value
    }
  },
  actions: {
    reset({commit, dispatch}) {
      commit('settings/resetState')
      commit('user/resetState')
      commit('categories/resetState')
      commit('restaurant/resetState')
      commit('citiesList/resetState')
      commit('banners/resetState')
      commit('aboutUs/resetState')

      return dispatch('loadAll').then(() => {
        commit('reset')
      })
    },
    loadAll({dispatch}) {
      setPagePreloader()
      return dispatch('settings/load').then(() => {
        return Promise.all([
          dispatch('categories/load'),
          dispatch('aboutUs/load'),
          dispatch('user/init'),
          dispatch('basket/subscribe'),
          dispatch('citiesList/load'),
        ]).finally(() => {
          removePagePreloader()
        })
      })
    }
  },
})

store.dispatch('loadAll')

function removePagePreloader() {
  if (!preloader) return

  preloader.addEventListener('transitionend', onEnd, false)
  preloader.style.opacity = 0

  function onEnd(e) {
    if (e.propertyName !== 'opacity') return
    preloader.removeEventListener('transitionend', onEnd, false)
    preloader.style.display = 'none'
  }
}

function setPagePreloader() {
  if (!preloader) return
  preloader.style.transition = '0s'
  preloader.style = null
}

export default store
