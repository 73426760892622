export default {
  namespaced: true,
  state: {
    menuLinks: [
      {
        name: 'Про нас',
        nameUrl: 'about-us'
      },
      {
        name: 'PDF меню',
        nameUrl: 'pdf-menu',
        pageId: 12
      },
      {
        name: 'Контакти',
        nameUrl: 'contacts'
      },
      {
        name: 'Доставка',
        nameUrl: 'delivery'
      },
      {
        name: 'Новини',
        nameUrl: 'events-list'
      },
      {
        name: 'Програма лояльності',
        nameUrl: 'loyalty',
        pageId: 11
      },
      {
        name: 'Політика конфіденційності',
        nameUrl: 'policy',
        pageId: 4
      },
      {
        name: 'Договір публічної оферти',
        nameUrl: 'public-offer',
        pageId: 3
      }
    ]
  }
}
