import {http} from '@/axios'

function getInitState() {
  return {
    pendingCities: false,
    loaded: false,
    promiseCitiesList: null,
    citiesList: []
  };
}

export default {
  namespaced: true,
  state: getInitState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getInitState());
    },
    setProperty(state, [key, value]) {
      if (!state.hasOwnProperty(key)) return;
      state[key] = value;
    }
  },
  actions: {
    load({state, commit}) {
      if (state.loaded) return Promise.resolve(state.citiesList)

      if(state.pendingCities) {
        return state.promiseCitiesList
      }

      commit('setProperty', ['pendingCities', true])

      const promiseCitiesList = http.get('/api/cities').then(({data}) => {
        commit('setProperty', ['citiesList', data.data])
        return Promise.resolve(data.data)
      }).finally(() => {
        commit('setProperty', ['loaded', true])
        commit('setProperty', ['pendingCities', false])
        commit('setProperty', ['promiseCitiesList', null]);
      })

      commit('setProperty', ['promiseCitiesList', promiseCitiesList]);

      return promiseCitiesList
    },
  },
}
