import {http} from '@/axios'
import router from '@/router'
import Vue from 'vue'
import {get} from 'lodash'

function getInitState() {
  return {
    loaded: false,
    pendingLoad: false,
    pendingAuth: false,
    pendingUpdate: false,
    profile: null,
    promiseUser: null,
    token: getTokenFromLocalStorage()
  }
}

export default {
  namespaced: true,
  state: getInitState(),
  getters: {
    isLogged(state) {
      return !!state.token
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getInitState())
    },
    setProperty(state, [key, value]) {
      if (!state.hasOwnProperty(key)) return
      state[key] = value
    }
  },
  actions: {
    init({commit, dispatch}) {
      let url = new URL(window.location)
      let urlToken = url.searchParams.get('t')
      if (urlToken) {
        addTokenToLocalStorage(urlToken)
        if (url.hash === '#_=_') {
          url.hash = ''
        }
        url.search = ''
        window.history.replaceState(null, document.title, url)
      }

      let token = getTokenFromLocalStorage()

      if (token) {
        commit('setProperty', ['token', token])
        http.defaults.headers.common['token'] = token
        dispatch('load')
      }
    },
    load({state, commit, dispatch}) {
      // if (state.loaded) {
      //   dispatch('notifications/load', null, {root: true})
      //   return Promise.resolve(state.profile)
      // }
      if (state.pendingLoad) {
        return state.promiseUser
      }
      commit('setProperty', ['pendingLoad', true])

      const promiseUser = http.get('/api/user', {
        params: {
          token: state.token
        }
      }).then(({data}) => {
        commit('setProperty', ['profile', data.item])
        dispatch('notifications/load', null, {root: true})
        return Promise.resolve(data.item)
      }).catch((e) => {
        router.push({name: 'home'})
        Vue.nextTick(() => {
          dispatch('logout')
          Vue.prototype.$vModal.open('message', {
            message: get(e.response.data, 'message') || e.message,
          })
        })
        throw e
      }).finally(() => {
        commit('setProperty', ['loaded', true])
        commit('setProperty', ['pendingLoad', false])
        commit('setProperty', ['promiseUser', null])
      })

      commit('setProperty', ['promiseUser', promiseUser])
      return promiseUser
    },
    registration({commit, dispatch}, sendData) {
      dispatch('logout')
      commit('setProperty', ['pendingAuth', true])

      return http.post('/api/user/sign-up', sendData).then(async ({data}) => {
        addTokenToLocalStorage(data.item.auth_key)
        commit('setProperty', ['profile', data.item])
        commit('setProperty', ['token', data.item.auth_key])
        http.defaults.headers.common['token'] = data.item.auth_key
        dispatch('notifications/load', null, {root: true})
        return data
      }).finally(() => {
        commit('setProperty', ['pendingAuth', false])
      })
    },
    login({commit, dispatch}, sendData) {
      dispatch('logout')
      commit('setProperty', ['pendingAuth', true])

      return http.post('/api/user/login', sendData).then(async ({data}) => {
        addTokenToLocalStorage(data.item.auth_key)
        commit('setProperty', ['profile', data.item])
        commit('setProperty', ['token', data.item.auth_key])
        http.defaults.headers.common['token'] = data.item.auth_key
        dispatch('notifications/load', null, {root: true})
        return data
      }).finally(() => {
        commit('setProperty', ['pendingAuth', false])
      })
    },
    updateProfile({commit, state, dispatch}, sendData) {
      commit('setProperty', ['pendingUpdate', true])
      return http.post(`/api/user/update/${state.profile.id}`, sendData).then(async ({data}) => {
        commit('setProperty', ['profile', data.item])
        dispatch('notifications/load', null, {root: true})
        return data
      }).finally(() => {
        commit('setProperty', ['pendingUpdate', false])
      })
    },
    changePassword({commit, dispatch}, sendData) {
      commit('setProperty', ['pendingUpdate', true])

      return http.post('/api/user/change-password', sendData).then(async ({data}) => {
        addTokenToLocalStorage(data.item.auth_key)
        commit('setProperty', ['profile', data.item])
        commit('setProperty', ['token', data.item.auth_key])
        commit('setProperty', ['loaded', false])
        http.defaults.headers.common['token'] = data.item.auth_key
        dispatch('notifications/load', null, {root: true})
        return data
      }).finally(() => {
        commit('setProperty', ['pendingUpdate', false])
      })
    },
    resetPassword({commit, dispatch}, sendData) {
      dispatch('logout')
      commit('setProperty', ['pendingAuth', true])

      return http.post('/api/user/reset-password-request', sendData).then(async ({data}) => {
        return data
      }).finally(() => {
        commit('setProperty', ['pendingAuth', false])
      })
    },
    restorePassword({commit, dispatch}, sendData) {
      dispatch('logout')
      commit('setProperty', ['pendingAuth', true])

      return http.post('/api/user/reset-password', sendData).then(async ({data}) => {
        return data
      }).finally(() => {
        commit('setProperty', ['pendingAuth', false])
      })
    },
    logout({commit, dispatch}) {
      commit('setProperty', ['profile', null])
      commit('setProperty', ['token', null])
      commit('setProperty', ['loaded', false])
      delete http.defaults.headers.common['token']
      removeTokenFromLocalStorage()
      dispatch('notifications/disablePusher', null, {root: true})

      const isRequiresAuth = !!router.currentRoute.matched.find(record => {
        return record.meta.requiresAuth
      })

      if (isRequiresAuth) {
        router.push({name: 'home'})
      }
    }
  }
}

function addTokenToLocalStorage(token) {
  if (!token) return
  return localStorage.setItem('token', token)
}

function removeTokenFromLocalStorage() {
  localStorage.removeItem('token')
}

function getTokenFromLocalStorage() {
  return localStorage.getItem('token') || null
}
