import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import getLangObjByCode from '@/i18n/common/getLangObjByCode'
import routes from './routes'
import beforeEnterRoot from './common/beforeEnterRoot'
import checkLang from './common/checkLang'
import scrollBehavior from './common/scrollBehavior'
import Root from '@/views/Root'
import store from '@/store'
import {deleteMetaRobots} from '@/helpers/metaRobots'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '_active',
  linkExactActiveClass: '_active-exact',
  scrollBehavior,
  routes: [
    {
      path: '/',
      redirect() {
        return {name: 'home', params: {locale: getLangObjByCode(i18n.locale).show_code}}
      }
    },
    {
      path: '/:locale',
      component: Root,
      children: routes,
      beforeEnter: beforeEnterRoot
    }
  ]
})

router.beforeEach((to, from, next) => {
  // -- remove meta robots --//
  deleteMetaRobots()

  //-- requiredUserTypes --//
  const isRequiresAuth = !!to.matched.find(record => {
    return record.meta.requiresAuth
  })

  if (isRequiresAuth && !store.getters['user/isLogged']) {
    const nextRoute = from && from.name && !from.meta.requiresAuth ? {
      name: from.name,
      params: from.params,
      query: from.query
    } : {name: 'home', params: {locale: to.params.locale || getLangObjByCode(i18n.locale).show_code}}
    next(nextRoute)
    Vue.nextTick(() => {
      setTimeout(() => {
        Vue.prototype.$vModal.open('auth-login', {
          redirect: {name: to.name}
        })
      }, 2000)
    })
  } else {
    checkLang(to, from, next)
  }
})

export default router
