import getLocaleFromBrowserStorage from './getLocaleFromBrowserStorage'
const {languages, defaultLanguageCode} = SETTINGS.LANGUAGES
const languagesCodes = languages.map(language => language.code)

export default function getLocale() {
  let locationPathname = location.pathname
  if (locationPathname.startsWith('/')) {
    locationPathname = locationPathname.slice(1)
  }

  let locale = locationPathname.split('/')[0]
  if (languagesCodes.includes(locale)) {
    return locale
  }

  locale = getLocaleFromBrowserStorage()
  if (languagesCodes.includes(locale)) {
    return locale
  }

  locale = getLocaleFromBrowser()
  if (locale) {
    return locale
  }

  return defaultLanguageCode
}

function getLocaleFromBrowser() {
  if (!window.navigator.language) return null

  let locale = window.navigator.language.split('-')
  if (locale[0]) locale[0] = locale[0].toLowerCase()
  if (locale[1]) locale[1] = locale[1].toLowerCase()

  for (let key of languagesCodes) {
    if (key === locale[0] || key === locale[1]) {
      return key
    }
  }
}