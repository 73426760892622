import axios from 'axios'

import i18n from './i18n'

export const baseURL = 'https://admin-solo.devseonet.com'

let instance = axios.create({
  baseURL: baseURL,
  timeout: 10 * 60 * 1000
})

instance.interceptors.request.use(function (config) {
  if (!config.params) {
    config.params = {}
  }

  if (!config.params.lang) {
    config.params['lang'] = i18n.locale
  }

  return config
})


export const http = instance
