import VModalComponent from './VModal'

export default {
  install(Vue, options) {
    const {router, store, i18n} = options
    Vue.component('v-modal', VModalComponent)

    // - - - -

    const ModalsList = options.ModalsList
    if (!ModalsList) {
      throw Error(`v-modal plugin aspect property 'ModalsList' in options object. Example: Vue.use(vModal, {rootContainer: vModalContainerComponent})`)
    }

    const root = new Vue({
      router,
      store,
      i18n,
      data: {
        modals: {},
        usedModals: [],
        lastModalsNames: []
      },
      render: createElement => createElement(ModalsList)
    })

    root.$mount(document.body.appendChild(document.createElement('div')))
    root.$on('close', () => {
      root.gallery.isOpen = false
      root.gallery.photos = []
      document.documentElement.removeEventListener('keyup', closeByESC)
    })

    Vue.prototype.$vModal = {
      open(name, props, zIndex) {
        if (!root.usedModals.includes(name)) {
          root.usedModals.push(name)
        }

        root.$nextTick(() => {
          root.$set(root.modals, name, {
            props,
            zIndex
          })

          root.lastModalsNames.push(name)
          document.documentElement.addEventListener('keyup', closeByESC)
        })
      },
      close(name) {
        if (name) {
          root.$delete(root.modals, name)

          let index = root.lastModalsNames.findIndex(item => item === name)
          if (index < 0) return
          root.lastModalsNames.splice(index, 1)
        } else {
          root.modals = {}
          root.lastModalsNames = []
        }
      },
      blockScroll() {
        // const scrollWidth = getScrollWidth();
        document.body.style.overflow = 'hidden'
        // document.body.style.paddingRight = scrollWidth + 'px'
      },
      unBlockScroll() {
        if (root.lastModalsNames.length) return

        document.body.style.overflow = null
        // document.body.style.paddingRight = null
      }
    }

    function closeByESC(e) {
      if (e.key === 'Escape') {

        if (root.lastModalsNames.length) {
          Vue.prototype.$vModal.close(root.lastModalsNames[root.lastModalsNames.length - 1])
        }

        if (!root.lastModalsNames.length) {
          document.documentElement.removeEventListener('keyup', closeByESC)
        }
      }
    }

  }
}

// function getScrollWidth() {
//   return (window.innerWidth - document.documentElement.clientWidth) || parseFloat(getComputedStyle(document.documentElement).paddingRight) || 0
// }

