export default {
  data() {
    return {
      windowSizeMixin: {
        width: null,
        height: null
      }
    }
  },
  methods: {
    windowSizeHandleMixin() {
      this.windowSizeMixin.width = window.innerWidth
      this.windowSizeMixin.height = window.innerHeight
    }
  },
  created() {
    window.addEventListener("resize", this.windowSizeHandleMixin)
    this.windowSizeHandleMixin()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowSizeHandleMixin)
  }
}
