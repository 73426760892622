import Vue from 'vue'
const BaseSvg = () => import(/* webpackChunkName: "base" */'./components/BaseSvg')
const BasePreloader = () => import(/* webpackChunkName: "base" */'./components/BasePreloader')
const BaseLabel = () => import(/* webpackChunkName: "base" */'./components/BaseLabel')
const BaseStatus = () => import(/* webpackChunkName: "base" */'./components/BaseStatus')
const BaseSelect = () => import(/* webpackChunkName: "form" */'./components/BaseSelect')
const BaseInput = () => import(/* webpackChunkName: "form" */'./components/BaseInput')
const BaseCheckbox = () => import(/* webpackChunkName: "form" */'./components/BaseCheckbox')
const BaseRadioList = () => import(/* webpackChunkName: "form" */'./components/BaseRadioList')

Vue.component('BaseSvg', BaseSvg)
Vue.component('BaseInput', BaseInput)
Vue.component('BaseCheckbox', BaseCheckbox)
Vue.component('BaseRadioList', BaseRadioList)
Vue.component('BasePreloader', BasePreloader)
Vue.component('BaseLabel', BaseLabel)
Vue.component('BaseSelect', BaseSelect)
Vue.component('BaseStatus', BaseStatus)
