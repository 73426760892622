const AccountPage = () => import(/* webpackChunkName: "account" */ '../views/Account/index')
const AccountPageProfile = () => import(/* webpackChunkName: "account" */ '../views/Account/AccountPageProfile')
const AccountPageProfileEdit = () => import(/* webpackChunkName: "account" */ '../views/Account/AccountPageProfileEdit')
const AccountPageOrders = () => import(/* webpackChunkName: "account" */ '../views/Account/AccountPageOrders')
const AccountPagePasswordEdit = () => import(/* webpackChunkName: "account" */ '../views/Account/AccountPagePasswordEdit')
const AccountPageMessages = () => import(/* webpackChunkName: "account" */ '../views/Account/AccountPageMessages')

export default [{
  path: 'account',
  name: 'account',
  component: AccountPage,
  redirect: {
    name: 'account-profile',
  },
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'profile',
      name: 'account-profile',
      component: AccountPageProfile
    },
    {
      path: 'profile/edit-profile',
      name: 'account-profile-edit',
      component: AccountPageProfileEdit
    },
    {
      path: 'orders',
      name: 'account-orders',
      component: AccountPageOrders
    },
    {
      path: 'edit-password',
      name: 'account-password-edit',
      component: AccountPagePasswordEdit
    },
    {
      path: 'messages',
      name: 'account-messages',
      component: AccountPageMessages
    }
  ]
}]
