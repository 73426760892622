<template>
  <div class="lang-select"
       :class="isOpen ? '_open' : null"
  >
    <div class="lang-select__trigger"
         @click="onToggleTrigger"
    >
      <span>{{currentLanguage.show_code}}</span>
      <BaseSvg name="ico-arrow-down"/>
    </div>
    <transition name="dropdown">
      <div v-if="isOpen"
           v-click-out="onClose"
           class="lang-select__dropdown">
        <div class="lang-select__options-list">
          <router-link v-for="language in languages"
                       :key="language.id"
                       :to="{params: {locale: language.show_code}}"
                       :style="{backgroundImage: language.image}"
                       class="lang-select__option">
            {{language.show_code}}
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  // import {languages} from '../i18n'

  export default {
    name: 'LangSelect',
    data() {
      return {
        isOpen: false
      }
    },
    props: {
      languages: {
        type: [Array, Object]
      }
    },
    computed: {
      currentLanguage() {
        return this.languages.find(language => language.show_code === this.$route.params.locale) || {}
      }
    },
    methods: {
      onToggleTrigger() {
        this.isOpen = !this.isOpen
      },
      onClose() {
        this.isOpen = false
      }
    },
    watch: {
      '$route.params.locale'() {
        this.onClose()
      }
    }
  }
</script>

<style>

</style>
