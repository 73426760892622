import {http} from '@/axios'
// import store from '@/store'
// import {isPlainObject} from 'lodash'

function getInitState() {
  return {
    pending: false,
    loaded: false,
    promiseSettings: null,
    settings: {},
  }
}

export default {
  namespaced: true,
  state: getInitState(),
  getters: {},
  mutations: {
    resetState(state) {
      Object.assign(state, getInitState())
    },
    setProperty(state, [key, value]) {
      if (!state.hasOwnProperty(key)) return
      state[key] = value
    }
  },
  actions: {
    load({state, commit}) {
      if (state.loaded) return Promise.resolve(state.settings)

      if (state.pending) {
        return state.promiseSettings
      }
      commit('setPending', true, {root: true})
      commit('setProperty', ['pending', true])

      const promiseSettings = http.get('/api/settings')
          .then(({data}) => {
            commit('setProperty', ['settings', data.settings])
            return Promise.resolve(data.settings)
          })
          .finally(() => {
            commit('setProperty', ['loaded', true])
            commit('setProperty', ['pending', false])
            commit('setProperty', ['promiseSettings', null])
            commit('setPending', false, {root: true})
          })

      commit('setProperty', ['promiseSettings', promiseSettings])

      return promiseSettings
    }
  }
}

// function formatSettings(obj) {
//   if(!isPlainObject(obj)) return
//
//   const newObj = {}
//
//   for (const key in obj) {
//     if(obj[key].hasOwnProperty('value')) {
//       newObj[key] = obj[key].value
//     } else {
//       newObj[key] = formatSettings(obj[key])
//     }
//   }
//
//   return newObj
// }